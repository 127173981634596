import React, {useEffect} from 'react';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import {Button, Popconfirm} from 'antd';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';

function AdminResourcePage(props) {
  const {location, path} = props;
  const {search} = location;
  const queryParams = qs.parse(search);
  const shouldClean = queryParams.clean === 'true';
  const [actions] = useOutlet('actions');

  // clean the query state hacky solution start
  useEffect(() => {
    if (shouldClean) {
      actions.navigate(path);
    }
  }, [actions, path, shouldClean]);

  if (shouldClean) {
    return null;
  }
  // clean the query state hacky solution end

  if (path === '/admin/products') {
    props.pageContext.resource.renderDeleteButton = (
      record,
      clientCallbacks,
    ) => {
      return (
        <Popconfirm
          title="你確定要刪除此商品嗎？"
          onConfirm={async () => {
            try {
              actions.setLoading(true);
              await JStorageActions.deleteDocument('product', {id: record.id});
              await clientCallbacks.refresh();
            } catch (ex) {
              console.warn(ex);
            } finally {
              actions.setLoading(false);
            }
          }}
          onCancel={() => 0}
          okText="確定"
          cancelText="取消">
          <Button danger>刪除</Button>
        </Popconfirm>
      );
    };
  }

  return <AdminResource {...props} />;
}

export default AdminResourcePage;
